import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Event as RouterEvent, NavigationStart,
         NavigationCancel, NavigationEnd, NavigationError } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  query,
  stagger,
  transition,
  AnimationEvent
} from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationService } from '../navigation/navigation.service';
import { Page, Settings } from '../navigation/pages.model';
import { ScrollToService } from '../animations/index';

/**
 * This class represents the navigation bar component.
 */
@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  public page: Page;
  public navigation: Page[] = [];
  public menu: any[] = [];
  public settings: Settings = new Settings;
  public loading = true;
  public menuVisible = 'invisible';
  public barVisible = false;
  public path: any = ['', '', ''];
  public top = '0';
  public left = '0';

  @Output() drawerToggle = new EventEmitter<boolean>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title,
             private metaService: Meta, private navigationService: NavigationService, private scroll: ScrollToService) {}

  ngOnInit() {

    this.navigationService.navigation.subscribe(this.pageChanged);
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

  }

  pageChanged = (menu: any) => {
    console.log('Got configuration');
    this.navigation = menu.menu.filter((page: Page) => +page.visible === 1);
    this.settings = menu.settings;
    this.loading = false;
  }

  hideDrawer() {
    this.drawerToggle.emit(true);
  }

  scrollAndNavigate(path: string, evt: any) {
    this.top = evt.target.offsetTop + 5 + 'px';
    this.left = evt.target.offsetLeft - 25 + 'px';
    this.scroll.scrollTo(0, () => {
      this.router.navigate([path]);
    }, 250);
  }

  setLoader(evt: any){
    this.top = evt.target.offsetTop + 5 + 'px';
    this.left = evt.target.offsetLeft - 25 + 'px';
  }

  navigationInterceptor = (event: RouterEvent) => {

      if (event instanceof NavigationStart) {
        this.loading = true;
        this.hideDrawer();
      }
      if (event instanceof NavigationEnd) {
       // console.log('end nav '+this.router.url);
       setTimeout(() => {
        this.loading = false;
        // this.toggleDrawer();
       }, 100);
       // find current page
       const params = this.router.url.split('/');
       const route = params[params.length - 1];
       this.path = params;
       this.page = this.navigation.filter(page => page.path === route)[0];
       if (this.page) {
        // page
          this.titleService.setTitle(this.page.title);
          this.metaService.updateTag({content: this.page.description}, 'name="description"');
          this.metaService.updateTag({content: this.page.keywords}, 'name="keywords"');
          // fb
          this.metaService.updateTag({content: 'fb'}, 'property="og:url"');
          this.metaService.updateTag({content: 'fb'}, 'property="og:type"');
          this.metaService.updateTag({content: 'fb'}, 'property="og:title"');
          this.metaService.updateTag({content: 'fb'}, 'property="og:description"');
          this.metaService.updateTag({content: 'fb'}, 'property="og:image"');
       }
      }

      //  Set loading state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel) {
          this.loading = false;
      }
      if (event instanceof NavigationError) {
          this.loading = false;
      }
  }

  menuAnimationDone(e: AnimationEvent) {
    if (e.toState === 'invisible') {
      this.menu = [];
    } else if (e.toState === 'visible') {
      this.menu = this.navigation;
    }
  }

}
