import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class ScrollToService {

    constructor() { /*constr*/ }

    //  easing functions http:// goo.gl/5HLl8
    easeInOutQuad = function (t:number, b:number, c:number, d:number) {
        t /= d/2;
        if (t < 1) {
            return c/2*t*t + b;
        }
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    easeInCubic = function(t:number, b:number, c:number, d:number) {
        var tc = (t/=d)*t*t;
        return b+c*(tc);
    };

    inOutQuintic = function(t:number, b:number, c:number, d:number) {
        var ts = (t/=d)*t,
        tc = ts*t;
        return b+c*(6*tc*ts + -15*ts*ts + 10*tc);
    };

    scrollTo(to: number, callback: any, duration: number) {
        function move(amount: number) {
            document.documentElement.scrollTop = amount;
            (<Element>document.body.parentNode).scrollTop = amount;
            document.body.scrollTop = amount;
        }
        function position() {
            return document.documentElement.scrollTop || (<Element>document.body.parentNode).scrollTop || document.body.scrollTop;
        }
        let animationFrameReqFunc = window.requestAnimationFrame || window.webkitRequestAnimationFrame ||
        (<any>window).mozRequestAnimationFrame || function( callback:any ) { window.setTimeout(callback, 1000 / 60); };
        let start = position(),
        change = to - start,
        currentTime = 0,
        increment = 20;
        duration = (typeof(duration) === 'undefined') ? 500 : duration;
        let animateScroll = () => {
            //  increment the time
            currentTime += increment;
            //  find the value with the quadratic in-out easing function
            let val = this.easeInCubic(currentTime, start, change, duration);
            //  move the document.body
            move(val);
            //  do the animation unless its over
            if (currentTime < duration) {
                animationFrameReqFunc(animateScroll);
            } else {
                if (callback && typeof(callback) === 'function') {
                    //  the animation is done so lets callback
                    callback();
                }
            }
        };
        animateScroll();
    }
}
