import { trigger, state, animate, transition, style } from '@angular/animations';

export const routeAnimation =
    trigger('routeAnimation', [
    state('*', style({opacity: 1, transform: 'perspective(800px) rotateY(0deg) translateX(0)'})),
    transition(':enter', [
      style({opacity: 0, transform: 'perspective(800px) rotateY(5deg) translateX(50px)'}),
      animate('1000ms 500ms cubic-bezier(.42,.97,.52,1.49)')
    ]),
    transition(':leave',
        animate('1000ms 0ms cubic-bezier(.42,.97,.52,1.49)',
            style({opacity: 0, transform: 'perspective(800px) rotateY(-5deg) translateX(-50px)'})
        )
    )]);
