import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DummyComponent } from './dummy/dummy.component';
import { CanActivateApp } from './app-activate.class';
import { HomeComponent } from './home/home.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
     
     {path: '**', component: DummyComponent, canActivate: [CanActivateApp]},
     {path: '', loadChildren: './about/about.module#AboutModule'},
     {path: '', loadChildren: './eco/eco.module#EcoModule'},
     {path: '', loadChildren: './flats/flats.module#FlatsModule'},
     {path: '', loadChildren: './company/company.module#CompanyModule'},
     {path: '', loadChildren: './contact/contact.module#ContactModule'},
     {path: '', loadChildren: './page/page.module#PageModule'},
     {path: '', loadChildren: './visualisation/visualisation.module#VisualisationModule'}

     
      /* define app module routes here, e.g., to lazily load a module
         (do not place feature module routes here, use an own -routing.module.ts in the feature instead)
       */
    ])
  ],
  exports: [RouterModule],
  providers: [CanActivateApp]
})
export class AppRoutingModule { }

