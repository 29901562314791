import { NgModule, LOCALE_ID } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HomeModule } from './home/home.module';
import { DummyModule } from './dummy/dummy.module';
import { SharedModule } from './shared/shared.module';
import { environment } from './../environments/environment';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      'swipe': {velocity: 0.4, threshold: 20, direction: 31}
  };
}



export function tokenGetter(): string { return localStorage.getItem('access_token'); }


@NgModule({
  imports: [
  // BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  AppRoutingModule,
  DummyModule,
  HomeModule,
  SharedModule.forRoot(),
  Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
  JwtModule.forRoot({
    config: {
      tokenGetter: tokenGetter,
      whitelistedDomains: [environment.api]
    }
  })],
  declarations: [AppComponent],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  bootstrap: [AppComponent]

})
export class AppModule { }
