import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buildingName'
})
export class BuildingNamePipe implements PipeTransform {

  transform(id: any): string {
    if (id == 1) {
      return 'Budynek D';
    } else if (id == 2) {
      return 'Budynek E';
    } else {
      return 'Budynek F';
    }
  }

}
