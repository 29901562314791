import { Component, ViewChild, HostListener, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { filter } from 'rxjs/operators';

/**
 * This class represents the main application component.
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('drawer') drawer: MatDrawer;
  public navMode = 'side';
  public navModeOpened = true;
  public popup = false;
  public showPopup = true;

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      if (event.target.innerWidth < 1279) {
        this.navMode = 'over';
        this.navModeOpened = false;
        // this.sidenav.close();
      }
      if (event.target.innerWidth > 1279) {
       this.navMode = 'side';
       this.navModeOpened = true;
       // this.sidenav.open();
      }
  }

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,private router: Router,private activatedRoute: ActivatedRoute) {
    // this.router.events.subscribe((event: RouterEvent) => {
    //   this.navigationInterceptor(event, activatedRoute);
    // });
  }
  // navigationInterceptor = (event: RouterEvent, activatedRoute: ActivatedRoute) => {


  //   this.router.events.pipe(filter(e => e instanceof NavigationEnd))
  //     .subscribe((s: NavigationEnd) => {
  //      console.log(s.url)
  //     })
  // }
  ngOnInit() {
    
    if (window.innerWidth < 1279) {
      this.navMode = 'over';
      this.navModeOpened = false;
    }
  }

  hideDrawer() {
    if (window.innerWidth < 1279) {
      this.drawer.close();
    }
  }

  closePopup(): void {
    this.showPopup = false;
  }
}
