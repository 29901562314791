import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availableFlatsCount'
})
export class AvailableFlatsCountPipe implements PipeTransform {

  transform(value: number): string {
    const mieszkania: { [key: number]: string } = {
      1: 'mieszkanie',
      2: 'dwa mieszkania',
      3: 'trzy mieszkania',
      4: 'cztery mieszkania',
      5: 'pięć mieszkań'
    };

    return mieszkania[value] || '';
  }

}
