import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, Settings, Setup } from './pages.model';
import { environment } from './../../../environments/environment';

/**
 * This class provides the NameList service with methods to read names and add names.
 */
@Injectable()
export class NavigationService {

  public isLoading = true;
  public navigation = new Subject();
  public pageSettings = new Subject();

  constructor(private httpClient: HttpClient) {}

  public items(): Observable<Page[]> {
    return this.httpClient.get<Page[]>(environment.api + '/app/menu');
  }

  public item(slug: string): Observable<Page> {
    return this.httpClient.get<Page>(environment.api + '/app/page/' + slug);
  }

  public settings(lang: string): Observable<Setup> {
    return this.httpClient.get<Setup>(environment.api + '/app/settings/' + lang);
  }

  public email(data: any): Observable<any> {
    return this.httpClient.post(environment.api + '/app/email', data);
  }

  private getNavigation() {
    return this.navigation;
  }

}

