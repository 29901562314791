import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'status'})
export class StatusPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) {
      return value;
    }
    switch (value) {
      case '1': {
        return 'wolne';
      }
      case '2': {
        return 'zarezerwowane';
      }
      case '3': {
        return 'sprzedane';
      }
    }
    return '';
  }
}
