import {Component, Input, Output, ElementRef, EventEmitter, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  trigger,
  state,
  style,
  animate,
  query,
  stagger,
  transition,
  AnimationEvent
} from '@angular/animations';

@Component({
  selector: 'app-gallery-dialog-component',
  templateUrl: 'gallery-dialog.component.html',
  styleUrls: ['gallery-dialog.component.scss'],
  animations: [
    trigger('navAnim', [
        transition(':enter', [
            style({ opacity: 0, top: '-50px' }),
            animate('250ms cubic-bezier(.42,0,.58,1)', style({ opacity: 1, top: 0 }))
        ]),
    ])
  ]
})
export class GalleryDialogComponent {

 public selected: number;
 public images: any;
 public image: string;
 public nav = false;

 constructor(public dialogRef: MatDialogRef<GalleryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
   this.selected = data.selected;
   this.images = data.images;
   this.image = this.images[this.selected].src;

   setTimeout(() => {
      this.nav = true;
   }, 400);
 }

 prev() {
    if (this.images.length > 0) {
      // this.imgLoading = true;
      this.selected--;
      if (this.selected < 0) {
        this.selected = this.images.length - 1;
      }
      this.image = this.images[this.selected].src;
    }
  }

  next() {
    if (this.images.length > 0) {
      // this.imgLoading = true;
      this.selected++;
      if (this.images.length === this.selected) {
        this.selected = 0;
      }
      this.image = this.images[this.selected].src;
    }
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-gallery',
  templateUrl: 'gallery.component.html',
  styleUrls: ['gallery.component.scss']
})

export class GalleryComponent implements OnInit {

  @Input('galleryImages') public galleryImages: any;

  constructor(private imageDialog: MatDialog) {}

  ngOnInit() {}

  openImageDialog(index: number, images: any): void {
    const dialogRef = this.imageDialog.open(GalleryDialogComponent, {
      panelClass: 'nopad-dialog',
      data: { selected: index, images: images}
    });
  }

  /*swipe(dir: string) {

      if ( dir === 'swipeleft') {
        this.nextImage();
      } else if ( dir === 'swiperight') {
        this.prevImage();
      }

  }*/
}
