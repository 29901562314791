import { Component, HostBinding, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ImageDialogComponent } from '../shared/dialogs/image-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { routeAnimation } from '../shared/animations/index';
import { ScrollToService, fadeInOutAnimation } from '../shared/animations/index';
import {
  trigger,
  state,
  style,
  animate,
  query,
  stagger,
  transition,
  AnimationEvent
} from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  animations: [
    fadeInOutAnimation,
    routeAnimation,
    trigger('productPicAnim', [
      state('visible', style({visibility: 'visible', opacity: 1})),
      state('invisible', style({visibility: 'hidden', opacity: 0})),
      transition('invisible => visible', [
        style({opacity: 0}),
        animate('2000ms cubic-bezier(.42,0,.58,1)')
      ]),
      transition('visible => invisible', [
        animate('2000ms cubic-bezier(.42,0,.58,1)',
        style({opacity: 0}))
      ])
    ]),
  ]
})

export class HomeComponent {
 public pageAnimated = 'invisible';
 public startAnim = false;
 public currentProductPicIndex = 1;
 public ideaTimer: any;
 @HostBinding('@routeAnimation') routeAnimation = true;
 @HostListener('@routeAnimation.done') pageAnimDone(e: AnimationEvent) {
    this.pageAnimated = 'visible';
 }

 constructor(private router: Router, private route: ActivatedRoute, private scroll: ScrollToService,
             private element: ElementRef, private renderer: Renderer2, private imageDialog: MatDialog) {
   // this.changePic();
   /* this.openImageDialog(); */
 }
 
 changePic(e: AnimationEvent) {
  if (e.toState === 'visible') {
    window.clearTimeout(this.ideaTimer);
    this.ideaTimer = setTimeout(() => {
     if (this.currentProductPicIndex < 13) {
       this.currentProductPicIndex++;
     } else {
       this.currentProductPicIndex = 1;
     }
    }, 4000);
  }
 }

 openImageDialog(): void {
    const dialogRef = this.imageDialog.open(ImageDialogComponent, {
      width: '1120px',
      // height: '630px',
      panelClass: 'nopad-dialog',
      data: { image: '/assets/popup.jpg', link: 'https://biznes.hvilepark.pl/'}
    });
 }

 scrollAndNavigate(path) {
  this.scroll.scrollTo(0, () => {
    this.router.navigate([path]);
  }, 250);
 }

}
