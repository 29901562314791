import { Injectable } from '@angular/core';
import { RouterModule, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NavigationService } from './shared/navigation/navigation.service';
import { Observable } from 'rxjs';

@Injectable()
export class CanActivateApp implements CanActivate {

private errorMessage: string;
private stringToComponent: any = {
  'HomeComponent': HomeComponent,
};
public slug:string;
constructor(private router: Router, private navigationService: NavigationService) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {

// this.navigationService.items().subscribe(
// pages => {
  this.navigationService.settings('pl-PL').subscribe(
   setup => {
    const menu: any = [];
    const pages = setup.menu;
    const settings = setup.settings;

    // for ( const i in pages.items ) {
    for (let i = 0; i < pages.length; i++) {
     let page;
     if ( pages[i].module in this.stringToComponent ) {
       page = {path: pages[i].path, component: this.stringToComponent[pages[i].module]};
     } else {
       // Lazy loaded route
       page = {path: pages[i].path, loadChildren: pages[i].module,pathMatch:'prefix'};
     }
     
     if (pages[i].children) {
      page.children = [];
      page.children.push({path: '', redirectTo: pages[i].children[0].path, pathMatch: 'full'}); // redirect to first of children
      for (let j = 0; j < pages[i].children.length; j++) {
       page.children.push({path: pages[i].children[j].path, component: this.stringToComponent[pages[i].children[j].module]});
      }
     }
     menu.push(page);

    }
    /*
    { path: '**', component: PageNotFoundComponent }
    */
    console.log('RESETING CONFIG');
    // console.log(menu);
    this.router.resetConfig(menu);
    // pages.items = pages.items.filter(page => page.path !== '');  // usuwa Stronę główną
    this.navigationService.navigation.next({menu: pages, settings: settings});
    if(state.url.indexOf('?')!=-1){
      this.slug=state.url.substring(0,state.url.indexOf('?'))
    } else{
      this.slug=state.url
    }
    
    // if(route.queryParams.gclid){
    //   this.router.navigate([this.slug],{ queryParams: route.queryParams });
    // } else{
    //   this.router.navigate([state.url]);
    // }
     this.router.navigate([this.slug]);
    return true;
   },
   error => {
    return false;
   }
  );

 /*},
 error => {
  return false;
 }
);*/
return false;
}
}


