import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { LanguageComponent } from './language/language.component';
// import { MatPaginatorIntl } from '@angular/material';
import { CustomMatPaginatorIntl } from './config/pagination.intl';

import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ImageDialogComponent } from './dialogs/image-dialog.component';
import { GalleryComponent, GalleryDialogComponent } from './gallery/gallery.component';

import { NavigationService } from './navigation/navigation.service';
import { ScrollToService } from './animations/index';

// pipes
import { SwitchPipe } from './pipes/switch.pipe';
import { StatusPipe } from './pipes/status.pipe';

import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatAutocompleteModule,
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatTableModule,
  MatDatepickerModule,
  MatSnackBarModule,
  MatTabsModule,
  MatStepperModule,
  MatExpansionModule,
  MatChipsModule,
  MatSortModule
} from '@angular/material';
import { AvailableFlatsPipe } from './pipes/available-flats.pipe';
import { BuildingNamePipe } from './pipes/building-name.pipe';
import { AvailableFlatsCountPipe } from './pipes/available-flats-count.pipe';


@NgModule({
  imports: [CommonModule, RouterModule, FlexLayoutModule, ReactiveFormsModule,
  MatDialogModule, MatDatepickerModule, MatTabsModule, MatToolbarModule, MatInputModule, MatMenuModule, MatIconModule,
  MatSlideToggleModule, MatSliderModule, MatInputModule, MatSnackBarModule, MatSelectModule, MatRadioModule, MatCheckboxModule,
  MatButtonModule, MatButtonToggleModule, MatSidenavModule, MatTableModule, MatListModule, MatTooltipModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatNativeDateModule, MatCardModule, MatPaginatorModule, MatStepperModule, MatAutocompleteModule,
  MatExpansionModule, MatChipsModule, MatSortModule],
  declarations: [LanguageComponent, NavbarComponent, FooterComponent, ImageDialogComponent, GalleryComponent, GalleryDialogComponent,
  SwitchPipe, StatusPipe, AvailableFlatsPipe, BuildingNamePipe, AvailableFlatsCountPipe],
  entryComponents: [ImageDialogComponent, GalleryComponent, GalleryDialogComponent],
  exports: [LanguageComponent, NavbarComponent, FooterComponent, ImageDialogComponent, GalleryComponent,
  CommonModule, FormsModule, RouterModule, FlexLayoutModule,
  MatDialogModule, MatDatepickerModule, MatTabsModule, MatToolbarModule, MatInputModule, MatMenuModule, MatIconModule,
  MatSlideToggleModule, MatSliderModule, MatInputModule, MatSnackBarModule, MatSelectModule, MatRadioModule, MatCheckboxModule,
  MatButtonModule, MatButtonToggleModule, MatSidenavModule, MatTableModule, MatListModule, MatTooltipModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatNativeDateModule, MatCardModule, MatPaginatorModule, MatStepperModule, MatAutocompleteModule,
  MatExpansionModule, MatChipsModule, MatSortModule, SwitchPipe, StatusPipe, AvailableFlatsPipe, BuildingNamePipe, AvailableFlatsCountPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [NavigationService, ScrollToService, /*{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}*/]
    };
  }
}
