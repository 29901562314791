import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

/**
 * This class represents the toolbar component.
 */
@Component({
  selector: 'app-image-dialog',
  templateUrl: 'image-dialog.component.html',
  styleUrls: ['image-dialog.component.scss']
})
export class ImageDialogComponent {

 public image: string;
 public link: string;

 @HostListener('click') onClick() {
  this.dialogRef.close();
 }

 constructor(public dialogRef: MatDialogRef<ImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  this.image = data.image.toLowerCase();
  this.link = data.link;
 }

}

