import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event as RouterEvent, NavigationStart,
         NavigationCancel, NavigationEnd, NavigationError } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationService } from '../navigation/navigation.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Page } from '../navigation/pages.model';

/**
 * This class represents the navigation bar component.
 */
@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})

export class FooterComponent implements OnInit {

 public page: Page;
 public navigation: any[] = [];
 public settings: any;
 public form: FormGroup;
 public res = 0;

 constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title,
             private metaService: Meta, private navigationService: NavigationService, private fb: FormBuilder) {}

 ngOnInit() {
  this.navigationService.navigation.subscribe(this.pageChanged);
  this.createForm();
 }

 createForm() {
  this.form = this.fb.group({
         email: ['', [Validators.required, Validators.email]],
  });
 }

 submit() {
    const formModel = this.form.value;
    this.navigationService.email(formModel).subscribe((result: any) => {
        this.res = result.message;
      }, error => {
        this.res = 2;
      }
    );
 }

 pageChanged = (menu: any) => {
  // console.log('Got footer configuration');
  this.navigation = menu.menu;
  this.settings = menu.settings;
 }
}
