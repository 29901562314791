import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availableFlats'
})
export class AvailableFlatsPipe implements PipeTransform {
  transform(availableFlats: number): string {
    if (availableFlats === 0) {
      return 'Sprzedane';
    } else if (availableFlats === 1) {
      return '1 mieszkanie';
    } else {
      return `${availableFlats} mieszkania`;
    }
  }
}