import { trigger, state, animate, transition, style } from '@angular/animations';

export const fadeInOutAnimation =
    trigger('fadeInOutAnimation', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('250ms cubic-bezier(.42,0,.58,1)', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('250ms cubic-bezier(.42,0,.58,1)', style({ opacity: 0 }))
        ]),
    ]);

    