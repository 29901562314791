export class Page {
    id: number;
    parent_id: number;
    ord: number;
    module: string;
    path: string;
    name: string;
    content: string;
    visible: string;
    title: string;
    keywords: string;
    description: string;
    lang: string;
    children:  any;
}

export class Settings {
    id: number;
    website_title: string;
    website_short_contact: string;
    website_phone: string;
    website_contact: string;
    google_use_analytics: number;
    google_key_analytics: string;
    google_use_maps: number;
    google_key_maps: string;
    google_maps_lat: string;
    google_maps_lng: string;
    google_use_captcha: number;
    google_key_captcha: string;
    facebook_use_profile: number;
    facebook_key_profile: string;
    facebook_use_pixel: number;
    facebook_key_pixel: string;
    facebook_title: string;
    facebook_description: string;
    facebook_image: string;
    cookies_use: number;
    cookies_message: string;
    seo_title: string;
    seo_description: string;
    seo_keywords: string;
    lang: string;
}

export class Setup {
    menu: Page[];
    settings: Settings;
}
