import { trigger, state, animate, transition, style } from '@angular/animations';

export const childrouteAnimation =
    trigger('childrouteAnimation', [
    state('*', style({opacity: 1, transform: 'translateY(0)'})),
    transition(':enter', [
      style({opacity: 0, transform: 'translateY(-50px)'}),
      animate('500ms 500ms cubic-bezier(.42,0,.58,1)')
    ]),
    transition(':leave',
        animate('500ms 0ms cubic-bezier(.42,0,.58,1)',
            style({opacity: 0, transform: 'translateY(50px)'})
        )
    )]);
