import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'switch'})
export class SwitchPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) {
      return value;
    }
    switch (value) {
      case '0': {
        return '0';
      }
      case '1': {
        return '1';
      }
      case '2': {
        return ' 2';
      }
    }
    return '';
  }
}
