import { Component } from '@angular/core';

@Component({
  selector: 'app-language',
  templateUrl: 'language.component.html',
  styles: [`:host{margin-left:.5rem}`]
})
export class LanguageComponent {

 public lang: string;

 constructor() {
  try {
   this.lang = localStorage.getItem('lang') || 'en-US';
  } catch (e) {
   //  Handle any errors (e.g. "SecurityError: The operation is insecure." if client blocks cookies.)
   this.lang = 'en-US';
  }
 }

 public selectLanguage = (lang: string) => {
  try {
   localStorage.setItem('lang', lang);
  } catch (e) {
   //  Handle any errors (e.g. "SecurityError: The operation is insecure." if client blocks cookies.)
  }
  window.location.reload();
 }
}
